<template>
<div class="reservation-view">
    <PtyBack @back="$router.push({name: 'OnBoarding'})" primary :style="{opacity: !exporting ? 1 : 0}"/>
    <div class="action-selection__content">

        <h1>{{ $t('Reservation.title') }}</h1>
        <p>
            {{ $t('Reservation.message') }}
        </p>


        <PtyReservation :reservation="reservation" />
        
        <PtyButton id="pty__Reservation_shareButton" v-if="!exporting && shareAvailable" block light-blue @click="share">
            {{ $t('Reservation.shareButton') }}
        </PtyButton>
        <PtyButton id="pty__Reservation_saveButton" v-if="!exporting" block @click="downloadAsFile">
            {{ $t('Reservation.saveButton') }}
        </PtyButton>

    </div>
</div>
</template>

    
<script>
import PtyReservation from '../components/PtyReservation.vue';
import PtyBack from '../components/PtyBack.vue';
import PtyButton from '../components/PtyButton.vue';
import html2canvas from 'html2canvas';

export default {
    name: 'Reservation',
    components: {
        PtyButton,
        PtyBack,
        PtyReservation
    },

    data(){
        return {
            exporting: false,
            shareAvailable: false,
        }
    },

    props: {
        reservation: {
            type: Object,
            default: () => ({ //fallback
                id: '',
                event: 'Loading..',
                date: '00',
                month: '--',
                location: 'Loading..',
                partecipants: [{
                    name: 'Loading..',
                    surname: 'Loading..',
                }],

                fullEvent: null,
            })
        }
    },

    methods: {
        async downloadAsFile() {
            const element = document.querySelector('.reservation-view');
            const loading = this.$vs.loading();
            this.exporting = true;
            
            // wait 500ms for the loading to show
            await new Promise(resolve => setTimeout(resolve, 500));
            
            html2canvas(element).then(canvas => {
                // add some padding


                const link = document.createElement('a');
                link.download = 'reservation.png';
                link.href = canvas.toDataURL('image/png');
                link.click();

                element.style.padding = '0';
                this.exporting = false;
                loading.close();
            });

            
        },

        async share(){
            const element = document.querySelector('.reservation-view');
            const loading = this.$vs.loading();
            this.exporting = true;
            
            // wait 500ms for the loading to show
            await new Promise(resolve => setTimeout(resolve, 500));
            
            html2canvas(element).then(canvas => {

                // to file (blob)
                canvas.toBlob(blob => {
                    const file = new File([blob], 'reservation.png', {type: 'image/png'});

                    // share
                    window.navigator.share({
                        title: 'Biglietto evento',
                        text: 'Ecco il tuo biglietto per l\'evento',
                        files: [file]
                    });
                });
                element.style.padding = '0';
                this.exporting = false;
                loading.close();
            });

        }
    },

    mounted() {
        if(!this.reservation.id){
            this.$router.push({name: 'OnBoarding'});
        }

        if(navigator.share){
            this.shareAvailable = true;
        }
    }
}
</script>

    
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.reservation-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    gap: 1.5rem;
    background: 
    url(../assets/images/illustrations/logos.png) top/130% auto no-repeat,
    linear-gradient(180deg, rgb(210, 251, 253) 16.57%, rgb(255, 255, 255) 53.63%);

}
</style>
